import React from "react"

import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../../components/Hero"
import Card from "../../components/ui/Card"
import TextLink from "../../components/ui/TextLink"
import RibbonText from "../../components/ui/RibbonText"
import ContactBanner from "../../components/ContactBanner"
import Seo from "../../components/Seo"

export default function WhyUseBuilderFinders() {
  return (
    <Layout>
      <Seo title="Why Use Builder Finders" />
      <Hero
        content={
          <div className="flex flex-col h-full justify-between">
            <h1 className="mb-12 max-w-md !normal-case">
              Why Use Builder Finders?
            </h1>
            <p className="sm:w-2/3 md:w-48">
              Dealing with Builder Finders enables you to deal with industry
              experts not just commission salespeople, giving you the confidence
              for your next project.
            </p>
          </div>
        }
        background={
          <StaticImage
            src="../../images/why-use-builder-finders.jpeg"
            className="w-full h-full"
            quality={100}
            alt="A modern brick house in hilly environment captured at dusk."
          />
        }
        overlay="bg-opacity-40"
      />
      <section className="wrapper py-6 lg:py-16">
        <div className="container grid sm:grid-cols-2 gap-10 lg:gap-20">
          <Card className="h-full">
            <div className="flex flex-col space-y-4 md:space-y-8">
              <h3>Why you need Builder Finders</h3>
              <p className="w-3/4">
                The task of coordinating the build of a home can be exhaustive
                and extremely costly. You may find the build price is so far
                over your budget and your dream project never comes to fruition.
              </p>
              <TextLink to="/what-we-do/">Learn more</TextLink>
              <h3>How will I save money?</h3>
              <p className="w-3/4">
                Time and time again, we have proven to save clients a
                significant amount of money. Builder Finders unique tendering
                process has shown to save you up to 5-10%.
              </p>
              <RibbonText
                text="It's that"
                highlighted="simple"
                justify="left"
              />
            </div>
          </Card>
          <div>
            <StaticImage
              src="../../images/white-modern-house.png"
              alt="A modern ivory white house a gray tiled garage ramp."
              className="sm:h-full"
            />
          </div>
        </div>
      </section>
      <section className="section bg-secondary-light">
        <div className="wrapper container py-12 lg:py-24">
          <div className="grid sm:grid-cols-2 gap-8 sm:gap-16">
            <div className="relative">
              <div className="absolute inset-0 pr-10">
                <div className="w-full h-full bg-primary" />
              </div>
              <div className="h-full pl-10 sm:pl-4 md:pl-10 py-4">
                <StaticImage
                  src="../../images/happy-client.png"
                  className="h-full"
                  alt="A smiling lady speaking in business attire with an elderly gentleman inside a brick meeting room."
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-4 md:gap-8">
              <div className="col-span-2 flex flex-row">
                <h2 className="w-2/3 md:w-80">
                  We work for <span className="text-tertiary">you</span> every
                  step of the way
                </h2>
              </div>
              <p className="sm:col-span-2 md:col-span-1">
                Developing a level of trust and respect is in our policy of
                openness and honesty. We gladly disclose the commission value of
                any project that we undertake. We will be transparent about our services, options and fees.
              </p>
              <p className="sm:col-span-2 md:col-span-1">
                Choosing Builder Finders gives you the ease and assurance of finding the right builder for you. Builder Finders even organises an independent registered builder to check on any defects and faults at handover. We are there until the end with you.
              </p>
              <div className="col-span-2 flex flex-row justify-end">
                <RibbonText
                  text="Trust the"
                  highlighted="experts"
                  justify="right"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactBanner />
    </Layout>
  )
}
