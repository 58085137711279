import { Link } from "gatsby"
import React from "react"

import Icon from "@material-ui/icons/ArrowRightAlt"

export default function TextLink({ children, to, className }) {
  return (
    <div className={className}>
      <Link
        to={to}
        className="group flex flex-row space-x-2.5 items-center opacity-50"
      >
        <p className="italic">{children}</p>
        <Icon
          color="inherit"
          className="transition-all duration-300 transform translate-x-0 group-hover:translate-x-2"
        />
      </Link>
    </div>
  )
}
